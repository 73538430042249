<template>
  <div class="pa-3">
    <v-row>
      <v-col cols="12" sm="6">
        <h4 class="mb-2">Recibidas - ({{ ordersReceived.length }})</h4>
        <v-row v-if="ordersReceived.length > 0" no-gutters>
          <v-col
            class="pl-1"
            cols="12"
            md="6"
            v-for="(item, index) in ordersReceived"
            :key="item.id"
          >
            <v-card
              outlined
              class="d-flex flex-column justify-space-between flex-grow-1"
            >
              <v-card-text :key="item.local_reference" class="pa-1">
                <v-list-item class="pa-1">
                  <v-list-item-content>
                    <div class="text-overline mb-2">
                      <div class="d-flex">
                        <v-chip
                          small
                          color="primary"
                          dark
                          outlined
                          v-if="item.name_table"
                        >
                          <v-icon left small>mdi-table-furniture</v-icon>Mesa:
                          {{ item.name_table }}
                        </v-chip>
                        <v-chip
                          small
                          color="primary"
                          dark
                          outlined
                          class="ml-1"
                        >
                          <v-icon small left
                            >mdi-format-list-bulleted-triangle</v-icon
                          >
                          Productos: {{ getTotalItems(item) }}
                        </v-chip>
                      </div>
                      <div class="d-flex flex-row justify-space-between">
                        <div class="time" v-if="item.opening_date">
                          <v-icon left small color="grey lighten-1"
                            >mdi-clock</v-icon
                          >{{ $filters.fromNow(item.opening_date) }}
                        </div>
                      </div>
                      <v-divider />
                    </div>
                    <v-row style="max-height: 180px; overflow-y: auto">
                      <v-col>
                        <div
                          v-for="it of item.data_items"
                          :key="it.id"
                          class="mb-1"
                        >
                          <v-chip color="primary" outlined small>
                            {{ it.quantity }}
                          </v-chip>
                          <span class="ma-1">x</span>
                          <span>{{ it.product.name }}</span>
                        </div>
                      </v-col>
                    </v-row>
                  </v-list-item-content>
                </v-list-item>
              </v-card-text>
              <v-btn-toggle tile class="d-flex flex-row">
                <v-btn rounded text color="info" class="flex-grow-1">
                  <v-icon left class="d-lg-block d-none" color="info"
                    >mdi-eye</v-icon
                  >
                  <v-icon class="d-lg-none d-block" color="info"
                    >mdi-eye</v-icon
                  >
                  <span class="d-lg-block d-none">Ver más</span>
                </v-btn>
                <v-btn
                  rounded
                  text
                  color="green"
                  @click="start(item)"
                  class="flex-grow-1"
                >
                  <v-icon left color="green">mdi-ray-start-arrow</v-icon>
                  <span>Preparar</span>
                </v-btn>
              </v-btn-toggle>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" sm="6">
        <h4 class="mb-2">En preparación - ({{ ordersPreparing.length }})</h4>
        <v-row v-if="ordersPreparing.length > 0" no-gutters>
          <v-col
            cols="12"
            md="6"
            class="pl-1"
            v-for="(item, index) in ordersPreparing"
            :key="item.id"
          >
            <v-card
              outlined
              class="d-flex flex-column justify-space-between flex-grow-1"
            >
              <v-card-text :key="item.local_reference" class="pa-1">
                <v-list-item class="pa-1">
                  <v-list-item-content>
                    <div class="text-overline mb-4">
                      <div class="d-flex">
                        <v-chip
                          small
                          color="primary"
                          dark
                          outlined
                          v-if="item.name_table"
                        >
                          <v-icon left small>mdi-table-furniture</v-icon>Mesa:
                          {{ item.name_table }}
                        </v-chip>
                        <v-chip
                          small
                          color="primary"
                          dark
                          outlined
                          class="ml-1"
                        >
                          <v-icon small left
                            >mdi-format-list-bulleted-triangle</v-icon
                          >
                          Productos: {{ getTotalItems(item) }}
                        </v-chip>
                      </div>
                      <div class="d-flex flex-row justify-space-between">
                        <div class="time" v-if="item.opening_date">
                          <v-icon left small color="grey lighten-1"
                            >mdi-clock</v-icon
                          >{{ $filters.fromNow(item.opening_date) }}
                        </div>
                      </div>
                      <v-divider />
                    </div>
                    <v-row style="max-height: 180px; overflow-y: auto">
                      <v-col>
                        <v-sheet
                          v-for="it of item.data_items"
                          :key="it.id"
                          class="mb-1 pa-1 d-flex align-center justify-space-between"
                          :class="{
                            'item-prepared':
                              it.status && it.status == 'prepared',
                            'item-preparing':
                              it.status && it.status == 'preparing',
                          }"
                        >
                          <div>
                            <v-chip
                              :color="getChipColor(it.status)"
                              outlined
                              small
                            >
                              {{ it.quantity }}
                            </v-chip>
                            <span class="ma-1">x</span>
                            <span>{{ it.product.name }}</span>
                          </div>
                          <v-btn
                            v-if="!it.status"
                            @click="startItem(item, it)"
                            oustlined
                            text
                            small
                            rounded
                            outlined
                            color="green"
                            >Iniciar</v-btn
                          >
                          <v-btn
                            v-if="it.status && it.status == 'preparing'"
                            @click="finishItem(item, it)"
                            small
                            dark
                            outlined
                            rounded
                            color="green"
                            >Preparado</v-btn
                          >
                        </v-sheet>
                      </v-col>
                    </v-row>
                  </v-list-item-content>
                </v-list-item>
              </v-card-text>
              <v-btn-toggle tile class="d-flex flex-row">
                <v-btn rounded text color="info" class="flex-grow-1">
                  <v-icon left class="d-lg-block d-none" color="info"
                    >mdi-eye</v-icon
                  >
                  <v-icon class="d-lg-none d-block" color="info"
                    >mdi-eye</v-icon
                  >
                  <span class="d-lg-block d-none">Ver más</span>
                </v-btn>
                <v-btn
                  rounded
                  text
                  color="green"
                  @click="finished(item)"
                  class="flex-grow-1"
                >
                  <v-icon left color="green">mdi-check-circle</v-icon>
                  <span>Finalizar</span>
                </v-btn>
              </v-btn-toggle>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  components: {},
  name: "TodoList",
  data: () => ({
    orders: [],
    key: 0,
    keyItemsChangeStatus: 1,
  }),
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
  },

  mounted() {
  
   /* setTimeout(() => {
      this.getPendingOrders();
    }, 500);*/
  },
  computed: {
    ordersReceived() {
      return this.orders.filter((el) => el.order_status === "received");
    },
    ordersPreparing() {
      return this.orders.filter((el) => el.order_status === "preparing");
    },
  },
  watch:{
    '$store.getters.store': function () {
      console.log('watch oreders listener....')
      this.getPendingOrders();
    }
  },
  methods: {
    getTotalItems(item) {
      return item.data_items.reduce((acc, it) => acc + it.quantity, 0);
    },
    getChipColor(status) {
      if (status === "prepared") {
        return "green";
      } else if (status === "preparing") {
        return "orange";
      } else {
        // Manejo de un caso por defecto
        return "grey"; // o cualquier otro color por defecto que desees
      }
    },
    getTotalItemsPreparing(item) {
      return item.data_items.reduce((acc, it) => {
        if (it.status === "preparing") {
          return acc + it.quantity;
        }
        return 0;
      }, 0);
    },
    getTotalItemsPrepared(item) {
      return item.data_items.reduce((acc, it) => {
        if (it.status === "prepared") {
          return acc + it.quantity;
        }
        return 0;
      }, 0);
    },
    startItem(order, it) {
      this.$set(it, "status", "preparing");
      this.$set(it, "start", Date.now());
      this.$set(order, "start", Date.now());
      //console.log(it)
      this.currentItemId = it.id;
      this.$http
        .post(
          "api/v1/order-change-status-item/" + order.id + "/" + it.id + "",
          { status: "preparing", start: it.start }
        )
        .then((response) => {
          if (response && response.success) {
          }
        });
    },
    finishItem(order, it) {
      this.$set(it, "status", "prepared");
      this.$set(it, "finished", Date.now());
      //this.$set(order, "finished", Date.now());
      //this.keyItemsChangeStatus++;
      this.currentItemId = it.id;
      this.$http
        .post(
          "api/v1/order-change-status-item/" + order.id + "/" + it.id + "",
          { status: "prepared", finished: it.finished }
        )
        .then((response) => {
          if (response && response.success) {

          }
        });
    },
    async getPendingOrders() {
      console.log("get pending orders..", this.$store.getters.store);
      if (this.$store.getters.store) {
        const subId = this.$store.getters.store.bus_subsidiary_id.id;
        return new Promise(async (resolve, reject) => {
          this.$http
            .get("api/v1/orders-pending-screen", {
              //from_seller: true,
              //order_status: ["received", "preparing"],
              subsidiary: [subId],
            })
            .then(async (response) => {
              this.$nextTick(() => {
                this.orders = response.items;
              });
              resolve(response.items);
              //this.$db.updateOrders( response.items );
            });
        });
      }
    },
    finished(item) {
      this.$http
        .post(
          "api/v1/order/change-status/" + item.id + "",
          { order_status: "prepared", time: this.$filters.currentDatetime() },
          true,
          true
        )
        .then((response) => {
          if (response && response.success) {
            //let indexToUpdate = this.orders.findIndex(el => el.id === item.id);
            item.order_status = "prepared";
          }
        });
    },
    start(item) {
      this.$http
        .post(
          "api/v1/order/change-status/" + item.id + "",
          { order_status: "preparing", time: this.$filters.currentDatetime() },
          true,
          true
        )
        .then((response) => {
          if (response && response.success) {
            //let indexToUpdate = this.orders.findIndex(el => el.id === item.id);
            item.order_status = "preparing";
          }
        });
      //item.order_status = 'preparing';
    },
    cancel() {
      this.clear();
      this.searched = false;
      this.key++;
      this.$emit("cancel");
    },
    clear() {
      this.item = JSON.parse(JSON.stringify(DEFAULT_ITEM));
    },
    addOrder(item) {

      if(this.orders.length == 0){
        this.orders.push(item);
      }else{
        let isNew = true;
        for (let i = 0; i < this.orders.length; i++) {
        if (this.orders[i].id === item.id) {
          // Actualizar el objeto existente en el array
          this.orders.splice(i, 1, JSON.parse(JSON.stringify(item)));
          isNew = false;
          break;
        }
      }
        if(isNew){
          this.orders.push(item);
        }
      }
      
    },
  },
};
</script>
<style scoped>
.item-preparing {
  background-color: #fdf3de;
  border: 1px solid #eeb345;
  border-radius: 4px;
}

.item-prepared {
  background-color: #ecf8ec;
  border: 1px solid #769c6d;
  border-radius: 4px;
}
</style>