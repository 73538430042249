<template>
  <div>
    <default-app-bar :showCancel="false">
      <template v-slot:menu>
        <v-app-bar-nav-icon @click.stop="toogleDrawer" color="primary"></v-app-bar-nav-icon>
      </template>
      <template v-slot:title>
        <v-toolbar-title class="px-0">
          <img src="/assets/images/brand/primary-icon-logo.svg" class="d-sm-block d-lg-none" width="40" />
          <img src="/assets/images/brand/primary-horizontal-logo.svg" class="d-none d-lg-block" width="180" />
        </v-toolbar-title>
      </template>

      <template v-slot:actions>
        <v-row>
          <v-col cols="12" class="d-flex flex-row align-center">

            <div class="d-flex flex-row align-center white rounded-pill py-1 px-2 me-2">
              <v-chip color="info" v-if="$store.getters.store">Sucursal: {{ $store.getters.store.name }}</v-chip>
           
              <v-btn fab small color="blue lighten-5" @click="updateInfo()" :title="$t('syncro')">
                <v-icon
                  color="blue">mdi-cloud-sync-outline</v-icon>
              </v-btn>
            </div>
            <!---User -->
            <v-menu bottom left offset-y origin="top right" transition="scale-transition" class="account-menu">
              <template v-slot:activator="{ on }">
                <v-btn dark icon v-on="on" class="ml-1" id="btn-menu-profile">
                  <v-avatar size="50" class="white">
                    <v-icon color="primary">mdi-account-outline</v-icon>
                  </v-avatar>
                </v-btn>
              </template>

              <v-list style="width:350px !important;">

                <v-list-item v-if="$store.getters.user" class="d-flex justify-center align-center">

                  <div class="account-header text-center">

                    <v-list-item two-line class="py-3 mx-auto">

                      <div>
                        <v-list-item-avatar v-if="$store.getters.company" class="mx-auto" size="60">

                          <img :src="$http.getBaseUrlPublicFiles() + $store.getters.company.logo"
                            :alt="$store.getters.company.name" />
                        </v-list-item-avatar>

                        <v-list-item-content class="secondary--text pa-0" id="info-company">
                          <v-list dense class="pa-0">
                            <v-list-item v-if="$store.getters.company" class="pa-0 ma-0 primary--text text-center">
                              <v-list-item-content v-if="$store.getters.company">
                                <v-list-item-title v-text="$store.getters.company.name"></v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>

                            <v-list-item v-if="$store.getters.user" class="pa-0 ma-0">
                              <v-list-item-content>
                                <v-list-item-title class="text-center grey--text" v-if="$store.getters.user"
                                  v-text="$store.getters.user.name" />
                                <v-list-item-subtitle class="text-center grey--text"
                                  v-if="$store.getters.user.rol && $store.getters.user.rol.name"
                                  v-text="$store.getters.user.rol.name"></v-list-item-subtitle>
                              </v-list-item-content>
                            </v-list-item>

                            <v-list-item v-if="app_version">
                              <v-list-item-content>
                                <v-list-item-title class="grey--text text--lighten-1 font-weight-light">
                                  v{{ app_version }}
                                </v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>

                          </v-list>
                        </v-list-item-content>
                      </div>
                    </v-list-item>
                  </div>
                </v-list-item>

                <v-divider></v-divider>

                <v-list-item id="menu-item-logout" class="text-center grey--text mt-2">
                  <v-btn @click="$store.dispatch('logoutAuth')" elevation="0" block outlined color="primary"
                    class="rounded-lg">{{
                      $t('logout') }}</v-btn>
                </v-list-item>

              </v-list>
            </v-menu>
            <!---User -->
          </v-col>
        </v-row>
        <!--a v-if="$store.getters.mobile" @click="dialogFilter = true"><v-icon>mdi-magnify</v-icon></a-->
      </template>

    </default-app-bar>

    <InstallScreen :open="openInstall" @cancel="cancelEditProduct()" @installed="installedOk"></InstallScreen>

    <todo-list ref="todoListRef" ></todo-list>
  </div>
</template>
<style scoped>
.fixed-bar {
  position: fixed;
  top: 48px;
  width: 100%;
  z-index: 2;
}

.cart_mobile {
  margin-top: 45px !important;
}
</style>
    
<script>
import InstallScreen from "./InstallScreen";
//import CartReceivedOrdersRemote from "./CartReceivedOrdersRemote";
import TodoList from "./TodoList";
import { listenOrderChanges } from "@/plugins/db-firestore";

export default {
  name: "ScreenIndex",
  components: {
    InstallScreen,
    //CartReceivedOrdersRemote,
    TodoList,
  },
  data: () => ({
    openInstall: false,
    openTurn: false,
    installed: false,
    currentOrder: null,
    modalItem: false,
    modalCartPendingOrders: false,
    currentProduct: false,
    currentItemLine: false,
    totalPendingOrders: 0,
    app_version: null,
    turn: null,
    showWarningOrderWithoutSave: false,
    showCartMobile: false,
    //cart: null
    //priceList:
  }),
  watch: {

    "$store.getters.online": function () {
      if (this.$store.getters.online) {
        //this.$pos.sincronizeSync();
      }
    },

    watch:{
    '$store.getters.store': function () {
      
      this.installedEvent();
    }
  },
 
  },
  mounted() {
    console.log('Screen home ok...')
    setTimeout(async () => {
      this.installedEvent();

    }, 500);
  },
  methods: {
  

    async testPrint() {
      const template = await this.$db.getTemplate(2);
      this.$store.dispatch("printData", {
        tmpl: template.body,
        info: { name: "" },
      });
    },
    openHelp() {
      window.open(process.env.VUE_APP_BASECONFIG_URL_HELP, "_blank");
    },
    async loadGeneralInfo() {
      //let orders = await this.$refs.todoListRef.getPendingOrders();
      
    },

    installedOk() {
      setTimeout(() => {
        //
        this.installedEvent();
        // window.location.href = "/pos";
      }, 1);
      //
    },
    async installedEvent() {
console.log(this.$store.getters.store)
       
        if (this.$store.getters.store) {
    
          this.installed = true;
          this.openInstall = false;
          this.loadGeneralInfo();
          const subId = this.$store.getters.store.bus_subsidiary_id.id;
          listenOrderChanges(
            subId,
            (doc, idEvent) => {
              this.getReceivedOrder(doc.order, idEvent);
            },
            null
          );
        } else {
          this.openInstall = true;
        }
      
      //this.load();
    },
    getReceivedOrder(id, idEvent) {
      console.log('changed order status....')
      const subId = this.$store.getters.store.bus_subsidiary_id.id;
      this.$http
        .get("api/v1/orders-pending", {
          id: id,
          subsidiary: [subId],
          order_status: "received",
          event: idEvent,
          type_event: "change_status",
        })
        .then(async (response) => {
          if (response.success && response.items.length > 0) {
            this.$refs.todoListRef.addOrder(response.items[0]);
            //const subId = this.$store.getters.store.id;
          }
        });
    },

    focusFieldQuantityScale() {
      setTimeout(() => {
        const element = document.getElementById("quantity-field");
        if (element !== null) {
          element.focus();
          element.select();
        }
      }, 325);
    },
    // adiciona los productos seleccionados, toma siempre la current list

    toogleDrawer() {
      this.$store.commit("toggle");
    },
  
    async updateInfo() {

      await this.$refs.modalPendingOrders.getPendingOrders();

    },
  },
};
</script>
    